import styled, { keyframes } from "styled-components";

export const MainWrapper = styled.div`
  overflow-x: : hidden;
  width: 100%;
`;

export const Header = styled.div`
  width: auto;
  height: 3.5rem;
  background-color: black;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #3d3d3d;
`;

export const LogoDiv = styled.div`
  margin-left: 20px;
  width: 6rem;
  height: 4rem;

  @media (max-width: 728px) {
    width: 6rem;
    height: 3rem;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const NavArea = styled.div`
  width: auto;
  height: auto;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  @media (max-width: 728px) {
    display: none;
  }

  span {
    color: #bdbdbd;
    font-size: 16px;
  }
  p {
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s ease-in-out, transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.35);
    }
  }
`;

export const ButtonArea = styled.div`
  width: auto;
  height: auto;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  p {
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s ease-in-out, transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.35);
    }
  }

  img {
    margin: auto;
    transition: 0.3s ease-in-out, transform 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.5);
    }
  }

  @media (max-width: 728px) {
  }
`;

export const ButtonPress = styled.button`
  background-color: #8c52ff;
  border: none;
  border-radius: 100px;
  color: white;
  height: 2.5rem;
  padding-top: 28px;
  padding-bottom: 28px;
  padding-right: 40px;
  padding-left: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    transform: scale(.95);
  }

  @media (max-width: 728px) {
    padding: 15px;
  }
`;

export const HamburgerDiv = styled.div`
  display: none;
  padding: 5px;

  @media (max-width: 728px) {
    display: block;
  }
`;

export const BodyWrapper = styled.div`
  width: auto;
  padding: 10px;
  padding-bottom: 2px;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BackCenter = styled.div`
  width: 55%;
  height: auto;
  margin-top: 6.6rem;
  margin-bottom: 2rem;
  background-image: url("/Vector.png");
  background-position: center;
  background-size: auto;
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: -10px;

  @media (max-width: 728px) {
    width: 100%;
    margin-top: 2rem;
  }
`;

export const ButtonPressBlack = styled.button`
  background: #989898;
  backdrop-filter: blur(10px);
  border: 0.2px solid #989898;
  border-radius: 100px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 30px;
  transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    background: #888;
    transform: scale(1.05);
  }

  @media (max-width: 728px) {
    font-size: 13px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

interface TextAreaProps {
  width?: string;
  bottom?: string;
}

export const TextArea = styled.div<TextAreaProps>`
  width: ${({ width = "55%" }) => width};
  height: auto;
  padding: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ bottom = "2px" }) => bottom};

  @media (max-width: 728px) {
    width: 100%;
  }

  h2 {
    font-size: 65px;
    font-style: normal;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin: auto;

    @media (max-width: 1100px) {
      font-size: 30px;
    }

    @media (max-width: 728px) {
      font-size: 22px;
    }
  }

  h3 {
    font-size: 75px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin: auto;
    letter-spacing: 2px;
    transform: rotate(0);

    @media (max-width: 1100px) {
      font-size: 30px;
    }

    @media (max-width: 728px) {
      font-size: 22px;
    }
  }

  p {
    width: 90%;
    font-size: 24px;
    color: #dcdcdc;
    font-weight: 400;
    text-align: center;

    @media (max-width: 728px) {
      font-size: 14px;
    }
  }

  br {
    @media (max-width: 728px) {
      display: none;
    }
  }
`;

export const ViewImage = styled.div`
  width: 99%;

  img {
    margin-top: 2rem;
    width: 100%;
    object-fit: cover;
  }
`;

export const BodyInformation = styled.div`
  margin-top: -5px;
  height: auto;
  background-color: #f8f8f8;
  padding-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;

  @media (max-width: 728px) {
    padding: 10px;
    padding-bottom: 30px;
  }
`;

export const ImageCollab = styled.div`
  margin-top: 8rem;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
  }

  @media (max-width: 728px) {
    margin-top: 3rem;
  }
`;

export const BoxArea = styled.div`
  margin-top: 20px;
  width: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 50px;
  margin-bottom: 70px;
`;

export const LongBox = styled.div`
  position: relative;
  height: 24rem;
  width: 63%;
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 1120px) {
    width: 95%;
    height: 24rem;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (max-width: 728px) {
    width: 100%;
    min-height: 15rem;
    height: 30rem;
  }
`;

export const BoxContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 728px) {
    flex-direction: column;
  }
`;

interface PropsSize {
  top?: string;
}

export const BoxImage = styled.div<PropsSize>`
  width: auto;
  height: 100%;
  margin-top: ${({ top = "50px" }) => top};

  img {
    object-fit: cover;
    height: 93%;

    @media (max-width: 728px) {
      height: 13rem;
      margin: 0;
    }
  }

  @media (max-width: 728px) {
    height: 50%;
    margin: 0;
  }
`;

export const BoxText = styled.div`
  width: auto;

  @media (max-width: 728px) {
    width: 100%;
  }
  .box-text-line {
    width: 100%;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      width: 70%;
    }
  }
`;

export const TextInBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 10px;

  @media (max-width: 728px) {
    gap: 20px;
  }

  p {
    color: #00112d;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: auto;

    @media (max-width: 728px) {
      font-size: 24px;
    }
  }

  span {
    color: rgba(0, 0, 0, 0.64);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;

    @media (max-width: 728px) {
      font-size: 16px;
    }
  }
`;

export const ShortBox = styled.div`
  height: 24rem;
  width: 30%;
  border-radius: 20px;
  background-color: #fff;

  @media (max-width: 1120px) {
    height: auto;
    width: 45%;
  }

  @media (max-width: 728px) {
    height: auto;
    width: 100%;
  }
  &.invest-box {
    height: auto;
    width: calc(50% - 25px);
    @media(max-width: 768px) {
      width: 100%;
    }
  }
`;

export const ShortTextInBox = styled.div`
  display: flex;
  height: 70%;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  padding: 20px;

  @media (max-width: 728px) {
    gap: 20px;
  }

  div {
    width: auto;

    img {
      width: 4rem;
      height: 4rem;
    }
  }

  p {
    color: #00112d;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: auto;
    margin-bottom: auto;

    @media (max-width: 728px) {
      font-size: 24px;
    }
  }

  span {
    color: rgba(0, 0, 0, 0.64);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;

    @media (max-width: 728px) {
      font-size: 16px;
    }
  }
`;

export const BusinessScale = styled.div`
  width: auto;
  height: auto;
  padding-top: 60px;
  background-color: black;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 728px) {
    padding: 10px;
    padding-bottom: 40px;
    padding-top: 50px;
  }
`;

export const BusinessTool = styled.div`
  margin-top: 30px;
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 728px) {
    gap: 20px;
    align-items: center;
    flex-direction: column;
  }
`;

export const ToolInformation = styled.div`
  width: 26rem;
  margin: 20px;

  @media (max-width: 728px) {
    width: 22rem;
  }

  div {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;

    div {
      width: 15px;
      margin-top: auto;
      margin-bottom: auto;

      img {
        width: 25px;
      }
    }

    i {
      width: auto;
    }

    p {
      color: #ffff;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  span {
    color: #bdbdbd;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`;

export const TalesHandler = styled.div`
  width: auto;
  height: auto;
  padding-top: 10px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TalesStory = styled.div`
  width: 30rem;
  height: auto;
  padding: 10px;
  margin-top: 90px;

  @media (max-width: 728px) {
    width: 100%;
    overflow: hidden;
  }

  h3 {
    text-align: center;
    font-size: 60px;
    color: black;
    margin: auto;

    @media (max-width: 728px) {
      font-size: 30px;
    }
  }
`;

export const MageStory = styled.div`
  margin-top: 1px;
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1100px) {
    justify-content: center;
  }

  @media (max-width: 728px) {
    align-items: center;
  }
`;

export const LeftMage = styled.div`
  width: 20%;
  height: 20rem;

  @media (max-width: 810px) {
    display: none;
  }

  @media (max-width: 728px) {
    display: none;
  }
`;

export const ScrollEffect = styled.div`
  overflow: hidden;
  height: 31rem;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  scrollbar-color: none;

  -ms-overflow-style: none;

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @media (max-width: 728px) {
    height: 22rem;
  }
`;

export const DivScroll = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  width: max-content;
  height: 100%;
`;

export const CenterMage = styled.div`
  width: 70%;
  height: 33rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;

  @media (max-width: 1100px) {
    width: 90%;
  }

  @media (max-width: 728px) {
    width: 90%;
    height: auto;
  }
`;

export const RightMage = styled.div`
  width: 20%;
  height: 20rem;

  @media (max-width: 810px) {
    display: none;
  }

  @media (max-width: 728px) {
    display: none;
  }
`;

export const CommentBox = styled.div`
  width: 60rem;
  height: 90%;
  border: 1px solid rgba(148, 148, 148, 0.18);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-left: 12px;

  @media (max-width: 1100px) {
    width: 49.5rem;
  }

  @media (max-width: 728px) {
    width: 20rem;
  }
`;

export const CommentAreaDiv = styled.div`
  width: 100%;
  height: 70%;
  border-bottom: 1px solid rgba(148, 148, 148, 0.18);
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 90%;
    height: 8rem;

    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const Navigator = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TextDiv = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    font-size: 24px;
    color: #3a3a3a;
    font-style: normal;
    font-weight: 400;

    @media (max-width: 728px) {
      font-size: 14px;
    }
  }
`;

export const Commentor = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 32%;
    display: flex;
    flex-direction: row;
    gap: 10px;

    @media (max-width: 728px) {
      width: 70%;
    }

    img {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      object-fit: cover;
    }

    > div {
      width: auto;

      p {
        color: #1a1a1a;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin: auto;

        @media (max-width: 728px) {
          font-size: 18px;
        }
      }

      span {
        color: #3a3a3a;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;

        @media (max-width: 728px) {
          font-size: 14px;
        }
      }
    }
  }
`;

export const SliderContainer = styled.div`
  overflow: hidden;
`;

export const SlideWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
`;

export const Questionaira = styled.div`
  width: auto;
  height: auto;
  padding: 60px;
  padding-top: 90px;
  padding-bottom: 50px;
  background-color: black;

  @media (max-width: 728px) {
    padding: 20px;
  }
`;

export const Frequently = styled.div`
  width: auto;
  height: auto;
  padding-bottom: 10px;

  > div {
    width: 45rem;
    height: auto;

    @media (max-width: 728px) {
      width: auto;
    }

    h3 {
      font-size: 70px;
      margin: auto;
      color: #fff;

      @media (max-width: 1100px) {
        font-size: 20px;
      }

      @media (max-width: 728px) {
        font-size: 30px;
      }
    }
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: auto;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
    height: auto;
  }
  to {
    opacity: 0;
    height: 0;
  }
`;

export const QuestionsAre = styled.div`
  width: auto;
  height: auto;
  margin-top: 30px;
  padding-bottom: 10px;
`;

export const Questions = styled.div`
  width: auto;
  height: auto;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 0.5s ease-out;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    p {
      color: #fff;
      font-size: 24px;
      font-weight: 400;

      @media (max-width: 728px) {
        font-size: 16px;
      }
    }

    span {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

       @media (max-width: 728px) {
        font-size: 16px;
      }
    }
  }
`;

export const AnswerContainer = styled.span<{ isVisible: boolean }>`
  width: auto;
  animation: ${({ isVisible = fadeOut || fadeIn }) =>
      isVisible ? fadeIn : fadeOut}
    0.5s ease-out;
  margin-bottom: 20px;
  color: #e2e2e2ac;

  @media (max-width: 728px) {
    width: auto;
  }

  span {
    font-size: 22px;
    font-weight: 300;

    @media (max-width: 728px) {
      font-size: 18px;
    }
  }
`;

export const AppCorner = styled.div`
  width: auto;
  height: auto;
  padding: 30px;
  padding-top: 10rem;
  padding-bottom: 10rem;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 728px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

export const DisplayArea = styled.div`
  width: 90%;
  height: 30rem;
  border-radius: 12px;
  background-image: url("/Group.png");
  background-position: center;
  background-size: cover;
  background-color: #8c52ff;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 728px) {
    width: 98%;
    padding: 10px;
    height: 20rem;
  }

  > div {
    width: 80%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;

    h3 {
      text-align: center;
      font-size: 65px;
      color: #fff;
      font-weight: 700;
      margin: auto;

      @media (max-width: 728px) {
        font-size: 35px;
      }
    }

    div {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      @media (max-width: 810px) {
        flex-direction: column;
      }

      img {
        width: 11rem;
      }
    }
  }
`;

export const Footer = styled.footer`
  width: auto;
  height: auto;
  background-color: black;
  padding: 40px;
  padding-bottom: 40px;

  @media (max-width: 728px) {
    padding: 5px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
  }
`;

export const FooterContent = styled.div`
  width: 100%;
  height: auto;
  padding: 10px;
  padding-top: 35px;
  border-top: 1px solid #3d3d3d;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const MerchroNavsArea = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 728px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const LogoArea = styled.div`
  width: auto;
  height: 3rem;
  border: 1px dashed white;
  padding-left: 20px;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;

  @media (max-width: 1120px) {
    width: auto;
    height: auto;
  }

  @media (max-width: 728px) {
    width: 63%;
    height: auto;
  }

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: auto;
      object-fit: contain;

      @media (max-width: 728px) {
        width: auto;
      }

      @media (max-width: 1100px) {
        width: auto;
      }
    }

    > div {
      width: 12rem;

      P {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        margin: 0px;
        margin-top: -13px;
      }

      div {
        width: auto;
        display: flex;
        gap: 10px;
        margin-top: 10px;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
`;

export const UseFulArea = styled.div`
  width: 60%;
  height: auto;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 20px;

  @media (max-width: 728px) {
    width: 100%;
    flex-direction: row;
  }

  > div {
    width: 200px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      margin-top: auto;
      margin-bottom: auto;
    }

    div {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    span {
      width: auto;
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      cursor: pointer;
      /* transition: 0.3s ease-in-out, transform 0.2s ease-in-out; */

      &:hover {
        transform: scale(1.15);
        color: #333;
      }

      a {
        text-decoration: none;
        color: white;
      }
    }

    @media (max-width: 728px) {
      width: 160px;
      margin-bottom: 3px;
    }
  }
`;

export const MerchroEnglish = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    width: auto;
    padding-right: 70px;

    @media (max-width: 728px) {
      padding-right: 0px;
    }

    span {
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      @media (max-width: 728px) {
        font-size: 16px;
      }
    }
  }
`;

interface AbsoluteImageProps {
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  mobile?: string;
  width?: string;
  size?: string;
  image?: string;
}
//  ${p => p.left || '15%'};
export const AbsoluteImage = styled.div<AbsoluteImageProps>`
  position: absolute;
  width: ${({ width = "230px" }) => width};
  padding-left: 10px;
  height: ${({ size = "200px" }) => size};
  margin-left: ${({ left = "0px" }) => left};
  margin-right: ${({ right = "auto" }) => right};
  margin-top: ${({ top = "0px" }) => top};
  margin-bottom: ${({ bottom = "0px" }) => bottom};

  @media (max-width: 1150px) {
    display: none;
  }

  span {
    @media (max-width: 728px) {
      display: none;
    }
  }

  img {
    margin-bottom: ${({ bottom = "0px" }) => bottom};
  }
`;

export const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-200px, -100px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const ImageHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;

const scrollAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const SlantDiv = styled.div`
  width: auto;
  overflow: hidden;
  transform: rotate(-1.703deg);
  margin-bottom: -10px;
  background: #eef4ff;
  display: flex;
  margin-top: -30px;
  margin-bottom: -30px;
  gap: 20px;

  > div {
    width: auto;
    display: flex;
    gap: 30px;
    animation: ${scrollAnimation} 1509s linear infinite;
    z-index: 50;
  }
`;

export const ContainerHolderDiv = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  p {
    font-size: 30px;
  }
`;
